import { Inject, Injectable } from '@angular/core';
import { MarcheAPIService } from './api/marche-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { ResponseWrapper } from '../@shared/models/response-wrapper.model';
import { AnalyticsService } from './analytics.service';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    static readonly SEO_DATA_API = '/seo';
    scriptTemplate = '';
    data: {
        title: string;
        description: string;
    } = {
        title: '',
        description: '',
    };
    link: HTMLLinkElement | null = null;

    constructor(
        private marcheApiService: MarcheAPIService,
        private analyticsService: AnalyticsService,
        public meta: Meta,
        public titleService: Title,
        @Inject(DOCUMENT) private doc: any
    ) {
        this.link = this.doc.createElement('link');
    }

    // updateSeo(filter: { [p: string]: string | string[] }): Observable<any> {
    //   return this.marcheApiService
    //     .getRequest<ResponseWrapper<any>>(SeoService.SEO_DATA_API, filter)
    //     .pipe(
    //       tap(res => {
    //         if (res?.success) {
    //           this.productDetail = res.data?.productDetail;
    //           this.data = res.data.meta;
    //           this.updateMetaTitle(this.data.title);
    //           this.updateMetaDescription(this.data.description);
    //           if (!!this.productDetail) {
    //             this.updateRichProductSnippets();
    //           }
    //         } else {
    //           this.updateMetaTitle('DailyObjects');
    //           this.updateMetaDescription('');
    //         }
    //       }),
    //     );
    // }

    updateMetaTitle(title: string) {
        this.titleService.setTitle(title);
        this.meta.updateTag({
            property: 'og:title',
            Content: title,
        });
    }

    updateMetaDescription(description: string) {
        this.meta.updateTag({
            name: 'description',
            Content: description,
        });
        this.meta.updateTag({
            property: 'og:description',
            Content: description,
        });
    }

    updateRichProductSnippets(productDetail: any) {
        const price = productDetail?.seo?.productDetail.price
            .match(new RegExp(/([0-9])/g))
            .join('');
        // const currency = productDetail?.seo?.productDetail.price
        //     .match(new RegExp(/([^0-9])/g))
        //     .join('');
        const brandName = productDetail?.seo?.productDetail.brand;

        let currencysymbols;
        let sellingPrice;
        if (environment.auth.mechanism === 'mobile') {
            currencysymbols = 'INR';
        } else {
            currencysymbols = 'USD';
        }

        let aggregateRating;

        if (productDetail.seo.rating) {
            aggregateRating = {
                '@type': 'AggregateRating',
                ratingValue: productDetail.seo.rating,
                reviewCount: productDetail.seo.reviewsCount,
            };
        }

        const seoScriptParam = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: productDetail.details.title?.text,
            brand: {
                '@type': 'Brand',
                name: brandName,
            },
            image: productDetail.carousel?.feed[0].url,
            sku: productDetail.seo.productDetail.sku,
            url: window.location.origin + productDetail.seo.canonical,
            availability: productDetail.seo.productDetail.status,
            offers: {
                '@type': 'Offer',
                price: price,
                priceCurrency: currencysymbols,
                availability: productDetail.seo.productDetail.status,
                priceValidUntil:
                    productDetail.seo.productDetail.priceValidUntil,
                hasMerchantReturnPolicy: {
                    '@type': 'MerchantReturnPolicy',
                    applicableCountry: 'IN',
                    returnPolicyCategory:
                        'https://schema.org/MerchantReturnFiniteReturnWindow',
                    merchantReturnDays:
                        productDetail.seo.productDetail.returnDays,
                    returnMethod: 'https://schema.org/ReturnByMail',
                    returnFees: 'https://schema.org/FreeReturn',
                },
                shippingDetails: {
                    '@type': 'OfferShippingDetails',
                    shippingRate: {
                        '@type': 'MonetaryAmount',
                        value: 0,
                        currency: 'INR',
                    },
                    shippingDestination: {
                        '@type': 'DefinedRegion',
                        addressCountry: 'IN',
                    },
                    deliveryTime: {
                        '@type': 'ShippingDeliveryTime',
                        handlingTime: {
                            '@type': 'QuantitativeValue',
                            minValue: 0,
                            maxValue:
                                productDetail.seo.productDetail.dispatchTime,
                            unitCode: 'DAY',
                        },
                        transitTime: {
                            '@type': 'QuantitativeValue',
                            minValue: 1,
                            maxValue: 5,
                            unitCode: 'DAY',
                        },
                    },
                },
            },
            description: productDetail.seo?.meta.description,
            aggregateRating: aggregateRating,
        };

        this.scriptTemplate = `
                <script type="application/ld+json">
                ${JSON.stringify(seoScriptParam)}
                </script>`;
        const myElement: HTMLElement | null = document.getElementById(
            'rich-product-snippet'
        );
        if (myElement) {
            myElement.innerHTML = this.scriptTemplate;
        }
        console.log(this.scriptTemplate, 'script2');
    }

    updateCanonical(canonical: string) {
        this.link?.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(this.link);
        this.link?.setAttribute('href', window.location.origin + canonical);
        if (canonical === '') {
            this.link?.setAttribute('href', window.location.href);
        }
        this.meta.updateTag({
            property: 'og:url',
            content: window.location.origin + canonical,
        });
    }

    updateHREFLang(langAlternates: Array<{ hreflang: string; href: string }>) {
        for (const { hreflang, href } of langAlternates) {
            const oldHreflang = window.document.querySelector(
                `link[hreflang=${hreflang}]`
            );
            if (!!oldHreflang) {
                oldHreflang.remove();
            }
            const link: HTMLLinkElement = this.doc.createElement('link');
            link.setAttribute('rel', 'alternate');
            link.setAttribute('hreflang', hreflang);
            link.setAttribute('href', href);
            this.doc.head.appendChild(link);
        }
    }

    addMetaTag(name: string, content: string) {
        this.meta.addTag({ name, content });
    }

    updateOgImage(image: string) {
        this.meta.updateTag({
            property: 'og:image',
            content: image + ',w-300,h-200',
        });
        this.meta.addTag({
            property: 'og:image:width',
            content: '300',
        });
        this.meta.addTag({
            property: 'og:image:height',
            content: '200',
        });
    }

    updateBreadcrums(list: Array<any>) {
        const breadcrumb: {
            '@type': any;
            position: any;
            name: any;
            item?: any;
        }[] = [];
        list?.forEach((element: any) => {
            const sample = {
                '@type': element?.type,
                position: element?.position + 1,
                name: element?.name,
                item: `${environment.host}${element?.item}`,
            };
            breadcrumb.push(sample);
        });

        this.scriptTemplate = `{"@context": "https://schema.org/","@type": "BreadcrumbList","itemListElement":${JSON.stringify(
            breadcrumb
        )}}`;
        const myElement: HTMLElement | null = document.getElementById(
            'rich-product-snippet-breadcrumb'
        );
        if (myElement) {
            myElement.setAttribute('type', 'application/ld+json');
            myElement.innerText = this.scriptTemplate;
        }
    }

    createGoogleFaqRichSnippet(faq: any) {
        const faqArray: {
            '@type': string;
            name: any;
            acceptedAnswer: { '@type': string; text: any };
        }[] = [];
        faq.forEach((element: any) => {
            const sample = {
                '@type': 'Question',
                name: element.que,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: element.ans,
                },
            };
            faqArray.push(sample);
        });

        this.scriptTemplate = `{"@context": "https://schema.org/","@type":"FAQPage","mainEntity":${JSON.stringify(
            faqArray
        )}}`;
        const myElement: HTMLElement | null =
            document.getElementById('faq-snippet');
        if (myElement) {
            myElement.setAttribute('type', 'application/ld+json');
            myElement.innerText = this.scriptTemplate;
        }
    }

    addRobotTag(tag: boolean) {
        if (tag) {
            this.meta.addTag({ name: 'robots', content: 'noindex' });
        }
    }

    cacheSingleUrl(data: any) {
        const fetchedCacheUrls = localStorage.getItem('cachedUrls') || '[]';
        let cachedUrls = JSON.parse(fetchedCacheUrls);
        if (
            !this.analyticsService.isBot(window.navigator.userAgent) &&
            cachedUrls.indexOf(data) == -1
        ) {
            this.marcheApiService
                .postRequest<ResponseWrapper<any> | null>(
                    '/cache-single-url-metadata',
                    undefined,
                    { url: data }
                )
                .subscribe(res => {
                    cachedUrls.push(data);
                    localStorage.setItem(
                        'cachedUrls',
                        JSON.stringify(cachedUrls)
                    );
                });
        }
    }

    updateMetaKeywords(keywords: string) {
        this.meta.updateTag({ name: 'keywords', content: keywords });
    }

    updateStaticPageSeo(filter: { [p: string]: string | string[] }) {
        this.marcheApiService
            .getRequest<ResponseWrapper<any>>(SeoService.SEO_DATA_API, {
                ...filter,
            })
            .subscribe(res => {
                if (res?.success) {
                    this.updateMetaTitle(res?.data?.meta?.title);
                    this.updateMetaDescription(res?.data?.meta?.description);
                    this.updateCanonical(res?.data?.canonical);
                    this.updateHREFLang(res?.data?.langAlternates);

                    this.updateOgImage(res?.data.ogImage);
                    if (res?.data?.faq.length > 0) {
                        this.createGoogleFaqRichSnippet(res?.data?.faq);
                    }
                    if (!!res?.data?.breadCrumbs) {
                        this.updateBreadcrums(res?.data?.breadCrumbs);
                    }
                }
            });
    }
}
